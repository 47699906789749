.App {
  text-align: center;
  background-color: rgb(25,25,25);
  max-height: 100vh;
  min-height: 100vh;
  position: relative;
}

.App-logo {
  width: 20vw;
  pointer-events: none;
}

.Logo-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: rgb(25,25,25);
}

.App-header {
  background-color: rgb(25,25,25);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header {
  width: 100vw;
  height: 100px;
  background-color: rgb(25,25,25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 35px;
  box-sizing: border-box;
  padding-right: 35px;
}

.header-logo {
  height: 30px;
  pointer-events: none;
  transition: .3s all;
  opacity: 1;
}

.container {
  overflow-y: scroll;
  width: 100vw;
  padding: 25px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: rgb(25,25,25);
}

.animation-enter {
  opacity: 0;
  scale: 2.5;
}
.animation-enter-active {
  opacity: 1;
  scale: 1;
  transition: all 600ms ease-in-out;
}
.animation-exit {
  opacity: 1;
}
.animation-exit-active {
  opacity: 0;
  transition: all 600ms ease-out;
}

.badge-animation-enter {
  opacity: 0;
  scale: 8;
  transform: translateY(-70vh);
}
.badge-animation-enter-active {
  opacity: 1;
  scale: 1;
  transform: translateY(0);
  transition: all 700ms ease-in;
}
.badge-animation-exit {
  opacity: 1;
}
.badge-animation-exit-active {
  transform: translateY(-70vh);
  transition: all 600ms ease-in-out;
}

.wave-animation-enter {
  opacity: 1;
  scale: 1;
}
.wave-animation-enter-active {
  opacity: 0;
  scale: 1.5;
  transition: all 500ms ease-out;
}
.wave-animation-exit {
  opacity: 0;
  scale: 1.5;
}
.wave-animation-exit-active {
  opacity: 0;
  scale: 1.5;
  transition: all 500ms ease-out;
}

.letter-left-enter {
  scale: 2;
}
.letter-left-enter-active {
  scale: 1;
  transition: all 600ms ease-in-out;
}
.letter-left-exit {
  transform: translateX(0px);
  scale: 1;
}
.letter-left-exit-active {
  scale: 4;
  transform: translateX(-100px);
  transition: all 600ms ease-out;
}

.letter-right-enter {

  scale: 2;
}
.letter-right-enter-active {
  scale: 1;
  transition: all 600ms ease-in-out;
}
.letter-right-exit {
  scale: 1;
  transform: translateX(0px);
}
.letter-right-exit-active {
  transform: translateX(100px);
  scale: 4;
  transition: all 600ms ease-out;
}

.right-to-left-enter {
  transform: translateX(100%);
}
.right-to-left-enter-active {
  transform: translateX(0);
  transition:all 1s ease;
}      

.right-to-left-exit {
  transform: translateX(0);
}
.right-to-left-exit-active {
  transform: translateX(100%);
  transition:all 1s ease;
} 

.slide-from-top-enter {
  transform: translateY(-70vh);
}
.slide-from-top-enter-active {
  transform: translateY(0);
  transition: all 600ms ease-in-out;
}
.slide-from-top-enter-done {
  transform: translateY(0);
  transition: all 600ms ease-in-out;
}
.slide-from-top-exit {
  transform: translateY(0);
}
.slide-from-top-exit-active {
  transform: translateY(-70vh);
  transition: all 600ms ease-in-out;
}

.slide-from-bottom-enter {
  transform: translateY(70vh);
}
.slide-from-bottom-enter-active {
  transform: translateY(0px);
  transition: all 600ms ease-in-out;
}
.slide-from-bottom-enter-done {
  transform: translateY(0px);
  transition: all 600ms ease-in-out;
}
.slide-from-bottom-exit {
  transform: translateY(0px);
}
.slide-from-bottom-exit-active {
  transform: translateY(70vh);
  transition: all 600ms ease-in-out;
}
.slide-from-bottom-exit-done {
  transform: translateY(0px);
  transition: all 600ms ease-in-out;
}

.slide-from-left-enter {
  transform: translateX(-100vw);
}
.slide-from-left-enter-active {
  transform: translateX(0);
  transition: all 600ms ease-in-out;
}
.slide-from-left-enter-done {
  transform: translateX(0);
  transition: all 600ms ease-in-out;
}
.slide-from-left-exit {
  transform: translateX(0);
}
.slide-from-left-exit-active {
  transform: translateX(100vw);
  transition: all 600ms ease-in-out;
}

.margin-top {
  margin-top: "30px"
}

.text-input {
  border-radius: 0px;
  width: 50vw;
  height: 100%;
  font-family: "PT Sans";
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  padding-left: 20px;
  flex: 1;
  box-shadow: none;
  color: white;
  background-color: #2C3137;
}

.login-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #2C3137;
  border-radius: 4px;
  height: 50px;
  width: 100%;
  overflow: hidden;
}

.login-text-container-square {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D6A461;
}

.question-container {
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.clues-container {
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #2C3137;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  box-sizing: border-box;
}

.login-button {
  margin-top: 20px;
  width: 100%;
  height: 50px;
  background-color: #D6A461;
  border: 0px;
  border-radius: 5px;
  font-family: "PT Sans";
  color: white;
  font-size: 16px;
  font-weight: "bold";
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel-button {
  margin-top: 20px;
  width: 100%;
  height: 50px;
  border: 0px;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "PT Sans";
  border: 2px solid #D6A461;
  color: white;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s;
}

.login-logo-container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.choice-button {
  background-color: #2C3137;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.choice-number {
  width: 50px;
  min-width: 50px;
  height: 100%;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D6A461;
  overflow: hidden;
}

.choice-number-animation {
  background-color: #D6A461;
}

.choice-number-animation-enter {
  width: 50px;
}
.choice-number-animation-enter-active {
  width: 100%;
  transition: all 200ms ease-out;
}
.choice-number-animation-enter-done {
  width: 100%;
  transition: all 200ms ease-out;
}
.choice-number-animation-exit {
  width: 100%;
}
.choice-number-animation-exit-active {
  width: 50px;
  transition: all 200ms ease-out;
}

.hidden-choice-number-animation-enter {
  width: 0px;
}
.hidden-choice-number-animation-enter-active {
  width: 100%;
  transition: all 200ms ease-out;
}
.hidden-choice-number-animation-enter-done {
  width: 100%;
  transition: all 200ms ease-out;
}
.hidden-choice-number-animation-exit {
  width: 100%;
}
.hidden-choice-number-animation-exit-active {
  width: 0px;
  transition: all 100ms ease-out;
}

.hidden-choice-number {
  width: 0px;
  height: 100%;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D6A461;
  overflow: hidden;
}

.choice-image {
  width: 100px;
  height: 100%;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D6A461;
  overflow: hidden;
}

.connect-choice-image {
  width: 100%;
  height: 100%;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D6A461;
  overflow: hidden;
}

.choice-image-animation-enter {
  width: 100px;
}
.choice-image-animation-enter-active {
  width: 100%;
  transition: all 200ms ease-out;
}
.choice-image-animation-enter-done {
  width: 100%;
  transition: all 200ms ease-out;
}
.choice-image-animation-exit {
  width: 100%;
}
.choice-image-animation-exit-active {
  width: 100px;
  transition: all 100ms ease-out;
}
.choice-image-animation-exit-done {
  width: 100px;
}

.fade-transition-enter {
  opacity: 0;
}
.fade-transition-enter-active {
  opacity: 1;
  transition: all 200ms ease-out;
}
.fade-transition-enter-done {
  opacity: 1;
  transition: all 200ms ease-out;
}
.fade-transition-exit {
  opacity: 1;
}
.fade-transition-exit-active {
  opacity: 0;
  transition: all 200ms ease-out;
}

.partial-fade-transition-enter {
  opacity: 0.5;
}
.partial-fade-transition-enter-active {
  opacity: 1;
  transition: all 200ms ease-out;
}
.partial-fade-transition-enter-done {
  opacity: 1;
  transition: all 200ms ease-out;
}
.partial-fade-transition-exit {
  opacity: 1;
}
.partial-fade-transition-exit-active {
  opacity: 0.5;
  transition: all 200ms ease-out;
}
.partial-fade-transition-exit-done {
  opacity: .5;
  transition: all 200ms ease-out;
}


.order-number {
  background-color: #61dafb;
}

.header-1 {
  font-weight: regular;
  font-size: 22px;
}

.header-2 {
  font-weight: regular;
  font-size: medium;
}

.body-1 {
  font-weight: regular;
  font-size: large;
}

.body-2 {
  font-weight: regular;
  font-size: medium;
}

.body-3 {
  font-weight: regular;
  font-size: small;
}

.body-4 {
  font-weight: regular;
  font-size: x-small;
}

@keyframes shake {
  0% {
    transform: translatex(0px);
  }
  25% {
    transform: translatex(30px);
  }
  50% {
    transform: translatex(-20px);
  }
  75% {
    transform: translatex(10px);
  }
  100% {
    transform: translateX(0px);
  }
}

.shake {
  animation: .3s shake;
}

.loader {
  border: 2px solid transparent; /* Light grey */
  border-top: 2px solid white; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.clue-button {
  height: 25px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #D6A461;
  border-radius: 3px;
  font-family: "PT Sans";
  color: white;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.points-container {
  height: 25px;
  background-color: #22252E;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 5px;
  padding-right: 20px;
  padding-left: 20px;
  align-items: center;
}

.split-line {
  height: 1px;
  background-color: rgba(255,255,255,0.15);
  width: 100%;
}

.list-square {
  height: 10px;
  width: 10px;
  min-height: 10px;
  min-width: 10px;
  border-radius: 3px;
  background-color: #D6A461;
  margin-right: 20px;
}

.tutorial-list-item {
  display: flex;
  margin-top: 20px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 20px;
}

.custom-checkbox {
  height: 24px;
  width: 24px;
  margin-right: 20px;
  background-color: black;
  border-radius: 3px;
  border: 2px solid white;
  justify-content: center;
  align-items: center;
  display: flex;
}

.inner-checkbox {
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 2px;
}

.door {
  position: fixed;
  width: 100vw;
  height: 50%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #2C3137;
}

.badge-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: transparent;
  z-index: 999;
}

.badge {
  position: fixed;
  width: 40vw;
  height: 40vw;
  top: 40vh;
  left: 30vw;
  border-radius: 20vw;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #D6A461;
}

.seal {
  display: block;
  width:   8.8rem;
  height:  8.8rem;

  position: fixed;
  left:     50%;
  top:      50%;
  margin:   -4.4rem 0 0 -4.4rem;
  border-radius: 4.6rem 3.8rem 4.6rem 3.9rem;
  background:   #D6A461;
  color:        #D6A461;
  border-color: #D6A461;

  box-shadow: 0 -4px 8px rgba(  0,   0,   0, 0.2) inset,
              0  6px 6px rgba(255, 255, 255, 0.6) inset,
              0  2px 10px rgba(  0,   0,   0, 0.8);

  text-align: center;
  line-height: 8.4rem;
  font-size:   4.4rem;

  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.seal .embossed {
  display: flex;
  width:   80%;
  height:  80%;
  justify-content: center;
  align-items: center;

  position: absolute;
  top:      10%;
  left:     10%;
  /* ... */
  box-shadow: 0  -4px 4px rgba(205, 205, 205, 0.1) inset,
                  0   6px 6px rgba( 30,  30,  30, 0.1) inset,
                  0   2px 4px rgba( 30,  30,  30, 0.6) inset;

      border-radius: 999rem;
      background-image: -webkit-linear-gradient(top,
                                                rgba( 51, 51, 51, 0.3) 0%,
                                                rgba( 51, 51, 51, 0.2) 100%);
    }

    .change-to-seal:before, .change-to-seal:after {
      display: block;
      content: ' ';
      position: absolute;
    
      line-height: 0;
      font-size:   0;
    
      background: inherit;
      overflow:   hidden;
    
      box-shadow: 0 1px 3px rgba(  0,   0,   0, 0.8),
                  0 3px 3px rgba(205, 205, 205, 0.4) inset;
    }

    .seal:after {
      width:   1.4rem;
      height:  1.4rem;
      top:      0%;
      left:     94%;
    
      border-radius: 2rem 1.8rem 1.9rem 1.6rem;
    }
    
    .seal:before {
      width:   0.6rem;
      height:  0.6rem;
      top:      0%;
      left:     85%;
    
      border-radius: 2rem 1.8rem 1.9rem 1.6rem;
    }

.stamp {
  filter: invert(70%) sepia(65%) saturate(307%) hue-rotate(354deg) brightness(90%) contrast(92%);
  height: 70%;
  width: 70%;
}

.seal-wave {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -9rem;
  margin-left: -9rem;
  height: 18rem;
  width: 18rem;
  border-radius: 200px;
  background: radial-gradient(transparent, transparent, transparent, #22252E, transparent, transparent, transparent);
}

label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  /* Style as you please, it will become the visible UI component. */
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.guest-book-image-container {
  max-height: 100%;
  height: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: #2C3137;
  overflow: hidden;
  border-radius: 4px;
  padding: 20px;
  text-align: left;
  box-sizing: border-box;
}

.guest-book-image {
  max-height: 100%;
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 4px;
  margin-top: 40px;
}

.magic-mirror-image {
  margin: auto;
  border-radius: 4px;
  object-fit: contain;
  background-color: #FFFFFF;
  border: solid 3vmin #FFFFFF;
}


.drop-1, .drop-2 {
  display: block;
  content: ' ';
  position: fixed;

  line-height: 0;
  font-size:   0;

  background: inherit;
  overflow:   hidden;
  background-color: #D6A461;

  box-shadow: 0 2px 6px rgba(  0,   0,   0, 0.8),
              0 3px 3px rgba(205, 205, 205, 1) inset;
}

.drop-1 {
  width:   1.4rem;
  height:  1.4rem;
  margin-left: 3.5rem;
  margin-top: -5rem;
  top:     50%;
  left:    50%;

  border-radius: 2rem 1.5rem 1.9rem 1.6rem;
}

.drop-2 {
  width:   0.6rem;
  height:  0.6rem;
  top: 50%;
  left: 50%;
  margin-left: 4.7rem;
  margin-top: -3.5rem;

  border-radius: 2rem 1.8rem 1.9rem 1.4rem;
}

.drop-1-enter {
  opacity: 0;
  scale: 12;
  transform: translateY(-70vh);
}
.drop-1-enter-active {
  opacity: 1;
  scale: 1;
  transform: translateY(0);
  transition: all 1000ms ease-in;
}
.drop-1-exit {
  opacity: 1;
}
.drop-1-exit-active {
  transform: translateY(-70vh);
  transition: all 600ms ease-in-out;
}

.drop-2-enter {
  opacity: 0;
  scale: 12;
  transform: translateY(-70vh);
}
.drop-2-enter-active {
  opacity: 1;
  scale: 1;
  transform: translateY(0);
  transition: all 1100ms ease-in;
}
.drop-2-exit {
  opacity: 1;
}
.drop-2-exit-active {
  transform: translateY(-70vh);
  transition: all 600ms ease-in-out;
}

.position-badge {
  background-color: #D6A461;
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 100px;
  position: relative;
  top: 0px;
  left: -15px;
}

.position-badge-border {
  border: 10px #88602c solid;
  height: 70px;
  width: 70px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 100px;
}

.ribbon {
  min-height: 40px;
  min-width: 30px;
  max-height: 40px;
  max-width: 30px;
  position: relative;
  top: 65px;
  left: 25px;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border: 15px solid red;
  border-bottom: 15px solid rgb(25,25,25);
  box-sizing: border-box;
}

.ribbon-bottom {
  width: 100%;
  height: 20px;
  background-color: #61dafb;
  border: 15px solid green;
  border-bottom: 15px solid black;
  box-sizing: border-box;
}

.big-button {
  margin-top: 20px;
  width: 100%;
  height: 100px;
  border: 2px #D6A461 solid;
  border-radius: 5px;
  font-family: "PT Sans";
  color: white;
  font-size: 20px;
  font-weight: "bold";
  display: flex;
  align-items: center;
  justify-content: center;
}

.newseal {
  display: block;
  width:   8.8rem;
  height:  8.8rem;

  position: fixed;
  left:     50%;
  top:      0%;
  margin:   -4.4rem 0 0 -4.4rem;
  border-radius: 4.6rem 4.6rem 4.6rem 4.6rem;
  background:   #D6A461;
  color:        #D6A461;
  border-color: #D6A461;

  box-shadow: 0 0px 0px rgba(  0,   0,   0, 0.2) inset,
              0  0px 0px rgba(255, 255, 255, 0.6) inset,
              0  0px 0px rgba(  0,   0,   0, 0.8);

  text-align: center;
  line-height: 8.4rem;
  font-size:   4.4rem;

  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.newseal .newembossed {
  display: flex;
  width:   90%;
  height:  90%;
  justify-content: center;
  align-items: center;

  position: absolute;
  top:      5%;
  left:     5%;
  /* ... */
  box-shadow: 0  -4px 4px rgba(205, 205, 205, 0.1) inset,
                  0   6px 6px rgba( 30,  30,  30, 0.1) inset,
                  0   2px 4px rgba( 30,  30,  30, 0.6) inset;

      border-radius: 999rem;
      background-image: -webkit-linear-gradient(top,
                                                rgba( 51, 51, 51, 0.3) 0%,
                                                rgba( 51, 51, 51, 0.2) 100%);
    }

    .newchange-to-seal:before, .newchange-to-seal:after {
      display: block;
      content: ' ';
      position: absolute;
    
      line-height: 0;
      font-size:   0;
    
      background: inherit;
      overflow:   hidden;
    
      box-shadow: 0 1px 3px rgba(  0,   0,   0, 0.8),
                  0 3px 3px rgba(205, 205, 205, 0.4) inset;
    }

    .newseal:after {
      width:   1.4rem;
      height:  1.4rem;
      top:      0%;
      left:     94%;
    
      border-radius: 2rem 1.8rem 1.9rem 1.6rem;
    }
    
    .newseal:before {
      width:   0.6rem;
      height:  0.6rem;
      top:      0%;
      left:     85%;
    
      border-radius: 2rem 1.8rem 1.9rem 1.6rem;
    }

.newstamp {
  filter: invert(70%) sepia(65%) saturate(307%) hue-rotate(354deg) brightness(90%) contrast(92%);
  height: 70%;
  width: 70%;
}

.pumpkin {
  height: 80%;
  width: 80%;
  object-fit: cover;
}