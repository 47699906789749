html {
  cursor: none;
}

body {
  margin: 0;
  font-family: "PT Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  white-space: nowrap;
}

code {
  font-family: "PT Sans";
}

textarea:focus, input:focus{
  outline: none;
  border: none;
}


textarea, input{
  outline: none;
  border: none;
}

input [type="file"] {
  display: none;
}

a{
  color: white
}